(function() {
    angular.module('EntrakV5').controller('deviceController', deviceController);

    function deviceController($scope, $timeout, $rootScope, Service, Api, KEY, APIKEY, $state, $stateParams) {
        console.log('deviceController');

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};
        $rootScope.hideTabs = false;

        $scope.degree = Service.translate("label.degree");
        $scope.searchText = null;
        $scope.locationIndex = 0;
        $scope.mapData = { markers: [] };
        $scope.maxLogCount = 300;

        var emptyDS = new kendo.data.DataSource({ data: [] });

        $scope.setSelectedLoc = function(i) {
          $scope.locationIndex = i;
          if ($scope.mapData.markers.length && $scope.deviceData.places) {
            $scope.mapData.markers[0].location = $scope.deviceData.places[$scope.locationIndex].location;
          } else {
            $scope.mapData.markers = [];
          }
        }

        $scope.airconCoolingLbl = function() {
          var l = $scope.deviceData.places[$scope.locationIndex].location;
          if (l.isCooling) {
            return ' - ' + Service.translate("label.cooling");
          } else if (l.isWarming) {
            return ' - ' + Service.translate("label.warming");
          }
          return ' - ' + Service.translate("label.normal");
        }

        $scope.logicLbl = function(logic, val, unit) {
          if (logic === "GTE") {
            return "≥" + (unit ? (val + unit) : val);
          } else if (logic === "LTE") {
            return "≤" + (unit ? (val + unit) : val);
          } else if (logic === "EQ") {
            return "=" + (unit ? (val + unit) : val);
          } else {
            return "N/A";
          }
        }

        $scope.initPage = function(){
          $scope.deviceData = null;
          $scope.timeBarData = null;
          $scope.displayStatusLog = null;
          $scope.setSelectedLoc(0);
        }
        $scope.initPage();

        var timer = null;
        $scope.updateSerialList = function(){
          $timeout.cancel(timer);
          timer = $timeout(function(){
            if ($scope.searchText) {
              caller.call(Api.searchDevice($scope.searchText)).then(function(res) {
                $scope.combo.setDataSource(new kendo.data.DataSource({ data: res }));
                $scope.combo.search($scope.searchText);
              });
            } else {
              $scope.combo.setDataSource(emptyDS);
            }
          }, 300);
        }

        $scope.search = function() {
          var id = $scope.combo.value();
          if (id) {
            $scope.getDevice(id);
          }
        }
        $scope.getDevice = function(id) {
            $rootScope.loadingPage++;
            $scope.btnStatus.searching = true;
            caller.call(Api.getDeviceDetails(id)).then(function(res) {
              $scope.getLog(res.device);
              $scope.getCurrentReadings(res.device);
              $rootScope.loadingPage--;
              $scope.btnStatus.searching = false;
              if (res.locations && res.locations.length){
                res.places = [];
                for (var i=0; i<res.locations.length; i++) {
                  var tmp = {
                    location: res.locations[i],
                    zone: Service.getArrItem(res.zones, res.locations[i].zoneId),
                  }
                  if (tmp.zone) {
                    tmp.building = Service.getArrItem(res.buildings, tmp.zone.buildingId);
                    for (var t=0; t<res.tenants.length; t++) {
                      if (Service.getArrItem(res.tenants[t].zones, tmp.zone.id)) {
                        tmp.tenant = res.tenants[t];
                        break;
                      }
                    }
                  }
                  res.places.push(tmp);
                }
              } else {
                res.places = null;
              }
              $scope.deviceData = res;
              $scope.mapData.markers = [res.device];
              $scope.setSelectedLoc(0);
            }, function(err) {
                if (err === KEY.ignore)
                  return;
                $rootScope.loadingPage--;
                $scope.btnStatus.searching = false;
                alert(Service.translate("error.generalGetDataFail"));
            });
        }

        $scope.comboOpt = {
          filter: "contains",
          minLength: 4,
          placeholder: Service.translate("label.search"),
          dataSource: emptyDS,
          change: $scope.search,
          dataTextField: "serialId",
          dataValueField: "id",
          syncValueAndText: false
         }

        function onInput(e) {
          var tmp = e.target.value.toUpperCase();
          if (tmp.length > 3) {
            if (tmp.indexOf($scope.searchText) > -1) {
              // reuse the same api response
            } else {
              $scope.searchText = tmp;
              $scope.updateSerialList();
            }
          } else {
            if ($scope.searchText) {
              $scope.searchText = null;
              $scope.updateSerialList();
            }
          }
        }
        $scope.showDevice = function(id, serial){
          if (id && serial){
            $scope.searchText = serial;
            $scope.combo.setDataSource(new kendo.data.DataSource({ data: [{id: id, serialId: serial}] }));
            $scope.combo.value(id);
            $scope.combo.trigger("change");
          }
        }
        $timeout(function(){
          $scope.combo.input[0].addEventListener("input", onInput);
          $scope.showDevice($stateParams.id, $stateParams.serial);
        });

        $rootScope.loadingPage++;
        caller.call(Api.getGateways(true)).then(function(res) {
          res.sort(Service.getSorter("serialId"));
          $scope.gatewayDropdown.setDataSource(new kendo.data.DataSource({ data: res }));
          $rootScope.loadingPage--;
        }, function(err){
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          alert(Service.translate("error.generalGetDataFail"));
        });

        $scope.createDeviceWinOpt = {
            title: Service.translate("tenant.createDevice"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
        }
        $scope.gatewayDropdownOpt = {
            dataTextField: "serialId",
            dataValueField: "id"
        }
        $scope.deviceTypeDropdownOpt = {
            dataSource: APIKEY.deviceTypeList.sort(),
        }
        $scope.deviceProviderDropdownOpt = {
            dataSource: APIKEY.deviceProviderList.sort(),
        }
        $scope.applicationTypeDropdownOpt = {
            dataSource: Object.keys(APIKEY.applicationTypeInv).filter(k => k !== APIKEY.applicationType.unknown && k !== APIKEY.applicationType.gateway).sort(),
        }

        $scope.createDevice = function(){
          $scope.createDeviceData = {
            serial: "",
            gatewayId: "",
            provider: null,
            deviceType: null,
            applicationType: null
          }
          setTimeout(function(){
              $scope.createDeviceWin.open().center();
          });
        }
        $scope.confirmCreateDevice = function(){
          $scope.btnStatus.saving = true;
          var serial = $scope.createDeviceData.serial.trim();
          caller.call(Api.createDevice(serial, $scope.createDeviceData.gatewayId, $scope.createDeviceData.provider, $scope.createDeviceData.deviceType, $scope.createDeviceData.applicationType)).then(function(res){
            $scope.showDevice(res, serial);
            $scope.btnStatus.saving = false;
            $scope.createDeviceWin.close();
          }, function(err){
            if (err === KEY.ignore)
              return;
            $scope.btnStatus.saving = false;
            alert(Service.translate("error.generalUpdate"));
          });
        }

        $scope.getCurrentReadings = function(device){
          if ($rootScope.haveReadingType(device.applicationType)){
            $rootScope.loadingPage++;
            $scope.readingData = { loading: true };
            caller.call(Api.getSensorData(device.id)).then(function(res){
                $scope.readingData = {};
                if (res.iaqReading){
                    res.iaqReading.forEach(function(data){
                        if (data.measurement === 'humidity'){
                            $scope.readingData[data.measurement] = Service.numFmt(data.avg*100);
                        } else {
                            $scope.readingData[data.measurement] = Service.numFmt(data.avg);
                        }
                    });
                }
                $scope.readingData.lux = res.lux;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.readingData.loading = false;
                $rootScope.loadingPage--;
                setTimeout(function(){
                    alert(Service.translate("error.generalGetDataFail"));
                }, 400);
            });
          }
        }

        $scope.logFilter = function(hideTemperature){
          if (hideTemperature && $scope.deviceData.device.applicationType === APIKEY.applicationType.aircon) {
            return function(d){
                return !d.reading;
            }
          }
        }

        $scope.getLog = function(device) {
          var logDurationInDay = 7;
          var dayLength = 24 * 3600;
          var endTime = new Date();
          endTime.setHours(0, 0, 0, 0);
          $scope.displayStatusLog = null;
          var aType = device.applicationType;
          var startTime = Service.getUnixTimestamp(endTime) - logDurationInDay * dayLength;//get 1 more day before start time
          var displayStartTime = startTime + dayLength;
          endTime = Service.getUnixTimestamp(endTime) + dayLength;
          var calls = [Api.getDeviceOnlineLog(device.id, startTime)];
          if (aType === APIKEY.applicationType.light || aType === APIKEY.applicationType.switch) {
            calls.push(Api.getDeviceLog(device.id, startTime, endTime, $scope.maxLogCount));
          } else if (aType === APIKEY.applicationType.aircon) {
            calls.push(Api.getDeviceLog(device.id, startTime, endTime, $scope.maxLogCount));
            calls.push(Api.getTemperatureSensorLog(device.id, startTime, endTime));
          } else if (aType === APIKEY.applicationType.thermometer) {
            $rootScope.loadingPage++;
            caller.call(Api.getTemperatureSensorLog(device.id, startTime, endTime)).then(function(res){
              if (res?.length && res[0].temperatures?.length) {
                res = res[0].temperatures;
                $scope.displayStatusLog = res.map(r => {
                  return {
                    timestamp: r.timestamp,
                    temperature: Service.numFmt(r.value, 1) + $scope.degree,
                  }
                }); // [{ temperatures { timestamp value } }]
              } else {
                $scope.displayStatusLog = [];
              }
              $rootScope.loadingPage--;
            }, function(err){
              if (err === KEY.ignore)
                return;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
            });
          } else if (aType === APIKEY.applicationType.iaqSensor) {
            $rootScope.loadingPage++;
            caller.call(Api.getIaqLog(device.serialId, startTime, endTime)).then(function(res){
              if (res?.length) {
                var co2Unit = Service.translate("label.ppm");
                var pm25Unit = Service.translate("label.ugm3");
                var tvocUnit = Service.translate("label.ppb");
                $scope.displayStatusLog = res.map(r => {
                  return {
                    timestamp: r.timestamp,
                    humidity: Service.numFmt(r.humidity* 100) + "%",
                    temperature: Service.numFmt(r.temperature, 1) + $scope.degree,
                    co2: Service.numFmt(r.temperature) + co2Unit,
                    pm25: Service.numFmt(r.temperature) + pm25Unit,
                    tvoc: Service.numFmt(r.temperature) + tvocUnit
                  }
                }); // { timestamp temperature humidity co2 pm25 tvoc }
              } else {
                $scope.displayStatusLog = [];
              }
              $rootScope.loadingPage--;
            }, function(err){
              if (err === KEY.ignore)
                return;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
            });
          } else if (aType === APIKEY.applicationType.lightSensor) {
            var lx = Service.translate("label.lx");
            $rootScope.loadingPage++;
            caller.call(Api.getLightSensorLog(device.id, startTime, endTime)).then(function(res){
              if (res.luxDatas){
                $scope.displayStatusLog = res.luxDatas.map(r => {
                  return {
                    timestamp: r.timestamp,
                    value: Service.numFmt(r.lux) + lx
                  }
                }); // { lux timestamp }
              } else {
                $scope.displayStatusLog = [];
              }
              $rootScope.loadingPage--;
            }, function(err){
              if (err === KEY.ignore)
                return;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
            });
          }
          $rootScope.loadingPage++;
          caller.call(calls).then(function(res){
            var tempRes = res[2];
            res = Service.processStatusLog(res[0], res[1], displayStartTime, logDurationInDay, true);
            $scope.timeBarData = res.barData;
            $scope.displayOnlineLog = res.displayOnlineLog;
            if (res.displayStatusLog) {
              res.displayStatusLog = aType === APIKEY.applicationType.aircon ? res.displayStatusLog.filter(l => l.status || l.temperature !== 0) : res.displayStatusLog;
              res.displayStatusLog.forEach(function(r){
                r.timestamp = r.time;
                if ($rootScope.haveReadingType(r.applicationType)){
                  r.value = Service.numFmt(r.temperature, 1) + $scope.degree;
                } else if (r.applicationType === APIKEY.applicationType.light){
                  r.value = r.level + "%";
                }
              });
              if (aType === APIKEY.applicationType.light || aType === APIKEY.applicationType.switch)
                $scope.displayStatusLog = res.displayStatusLog;
            }

            if (aType === APIKEY.applicationType.aircon && res.displayStatusLog && tempRes && tempRes[0]?.temperatures) {
              tempRes = tempRes[0]?.temperatures;
              $scope.displayStatusLog = [];
              var i = 0;
              var j = 0;
              // assuming both list from api are already sorted in descending order
              for (; i<res.displayStatusLog.length || j<tempRes.length;) {
                if (res.displayStatusLog.length == i) {
                  $scope.displayStatusLog = $scope.displayStatusLog.concat(tempRes.slice(j).map(r => {
                    return {
                      timestamp: r.timestamp,
                      temperature: Service.numFmt(r.value, 1) + $scope.degree,
                      reading: true
                    }
                  }));
                  break;
                } else if (tempRes.length == j) {
                  $scope.displayStatusLog = $scope.displayStatusLog.concat(res.displayStatusLog.slice(i));
                  break;
                }
                if (res.displayStatusLog[i].timestamp > tempRes[j].timestamp) {
                  $scope.displayStatusLog.push(res.displayStatusLog[i]);
                  i++;
                } else {
                  $scope.displayStatusLog.push({
                    timestamp: tempRes[j].timestamp,
                    temperature: Service.numFmt(tempRes[j].value, 1) + $scope.degree,
                    reading: true
                  });
                  j++;
                }
              }
            }

            if (!$scope.displayStatusLog)
              $scope.displayStatusLog = [];

            $rootScope.loadingPage--;
          }, function(err){
            if (err === KEY.ignore)
              return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
          });
        }

        $scope.barTooltipOpt = {
            filter: ".bar",
            position: "top",
            width: "auto",
            callout: true,
            animation: {
                open: {
                    effects: "zoom",
                    duration: 150
                }
            },
            content: function(e) {
                var t = e.target;
                var txt = Service.timeFmt(parseInt(t.data("start"))) + ' - ' + Service.timeFmt(parseInt(t.data("end")));
                if (t.hasClass("online")){
                    txt += " (" + Service.translate("tenant.online") + ")";
                } else if (e.target.hasClass("offline")){
                    txt += " (" + Service.translate("tenant.offline") + ")";
                }
                return txt;
            },
            show: function(e) {
                e.sender.popup.element.css({"margin-top": "-5px"});
            }
        }

        $scope.turnOnOffDevice = function(isOn){
          var d = $scope.deviceData.device;
          caller.call(Api.performDeviceAction(d.id, isOn ? APIKEY.action.on : APIKEY.action.off)).then(null, function(err){
            if (err === KEY.ignore)
              return;
            alert(Service.translate("error.generalControlFail"));
          });
        }

        $scope.normalTemperature = function(){
          var d = $scope.deviceData.device;
          $scope.btnStatus.normaling = true;
          caller.call(Api.normalDevice(d.id)).then(function(res){
            $scope.btnStatus.normaling = false;
            if ($scope.deviceData.places) {
              $scope.deviceData.places.forEach(function(p){
                p.location.isCooling = false;
                p.location.isWarming = false;
              });
            }
          }, function(err){
              $scope.btnStatus.normaling = false;
              if (err === KEY.ignore)
                  return;
              alert(Service.translate("error.generalControlFail"));
          });
        }

        $scope.blinkLight = function(){
          var d = $scope.deviceData.device;
          $scope.btnStatus.blinking = true;
          $timeout(function() {
            $scope.btnStatus.blinking = false;
          }, 2000);
          caller.call(Api.performDeviceAction(d.id, APIKEY.action.blink)).then(null, function(err){
              if (err === KEY.ignore)
                  return;
              alert(Service.translate("error.generalControlFail"));
          });
        }

        $scope.removeDeviceFromFloor = function(){
            $rootScope.loadingPage++;
            caller.call(Api.deleteDeviceLoc($scope.deviceData.device.location.id)).then(function(res){
                $scope.deviceData.device.location = null;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalDeleteFail"));
            });
            $scope.mapPopup.close();
        }

        $scope.confirmUnpairDevice = function(){
            $rootScope.loadingPage++;
            caller.call(Api.unpairDevice($scope.deviceData.device.id)).then(function(res) {
                $rootScope.loadingPage--;
                $scope.initPage();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalUnpairDevice"));
            });
        }
        $scope.unpairDevice = function(){
            $rootScope.deletePopup.show("tenant.popup.unpairDevice", "tenant.popup.unpairDeviceDesc", $scope.deviceData.device.serialId, $scope.confirmUnpairDevice);
        }

        $scope.$on('$destroy', function() {
            console.log("deviceController destroy");
            $scope.combo.input[0].removeEventListener("input", onInput);
            caller.cancel();
        });
    }
})();